@import "feather-icon/feather-icon";

@import "theme/variables";
@import "theme/admin_style";
@import "theme/rtl";
@import "theme/chartist.scss";
@import "theme/ngx-toastr.scss";
@import "theme/datatable";
@import "icons/icoicon/icons";
// Flag contents
@import "flag-icon/flag-icon-base";
@import "flag-icon/flag-icon-more";
@import "flag-icon/flag-icon-list";
@import "icons/themify/themify-icons";
@import "dropzone/dist/min/dropzone.min.css";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@import "quill/dist/quill.snow.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "react-infinite-calendar/styles.css";

@import "sweetalert2/src/sweetalert2.scss";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";
@import "dropzone/dist/min/dropzone.min.css";
@import "ngx-lightbox/lightbox.css";

.form-group {
  margin-bottom: 2rem;
}

.button-width {
  width: 135px;
}

.img-square,
.btn-primary,
.btn-secondary {
  border-radius: 10px;
}

.eye-icon {
  margin-top: 14px;
  margin-left: -40px;
  cursor: pointer;
}

.password-field {
  z-index: 10;
  margin-left: -30px;
  cursor: pointer;
}

.display-error {
  position: absolute;
  margin-top: 51px;
}

.brand-logo {
  height: 65px;
}

.card-setting {
  min-height: 430px;
}

.card-body {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  min-height: 430px;
}

.mat-card-content {
  min-height: 430px;
}

.add-title {
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: $primary-color;
}

.add-title-role {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: rgba(68, 84, 106, 0.51);
}

.back-arrow {
  margin-top: -5px;
  margin-right: 5px;
  cursor: pointer;
}

.logo-min-width {
  min-width: 127px !important;
}

.img-placeholder-width {
  width: 100px;
  height: 100px;
  object-fit: scale-down;
  border-radius: 10px;
}

.img-placeholder-width2 {
  object-fit: cover;
  border-radius: 10px;
}

.cursor {
  cursor: pointer;
}

agm-map {
  height: 250px;
}

.agm-map-container-inner {
  border-radius: 20px;
}

.brand-manager-dashboard {
  .nav-link.active {
    color: $theme-deafult;
    border-bottom: solid 1px $theme-deafult;
    font-weight: 600;
  }

  .nav-link {
    font-family: Lato;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 0.51px;
    text-align: left;
    border: none;
    color: rgba(68, 84, 106, 0.5);
    padding-inline: 0;
    margin-right: 20px;
  }

  .cashout-balance {
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: $theme-deafult;

    .focus-text {
      font-family: Lato;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-bottom: 2px;
    }

    .detail-text {
      margin-bottom: 5px;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .booked-spots {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #eaedf3 !important;

    .focus-text {
      font-family: Lato;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: $primary-color;
      margin-bottom: 2px;
    }

    .detail-text {
      margin-bottom: 5px;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: $primary-color;
    }
  }
}

.filter-list {
  border-radius: 16px;
  background-color: #eaedf3;
  padding: 9px 16px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin-right: 10px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  color: #44546a;
}

.verification_status {
  border-radius: 13px;
  background-color: #eaedf3;
  padding: 5px 10px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin-right: 10px;
  border: none;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.36;
  color: #44546a;
}

.selected-filter {
  color: white;
  background: $primary-color !important;
}

@media screen and (max-width: 1366px) {
  .width-adjust {
    width: 700px;
    margin-bottom: 10px;
  }

  .recently-visited-users {
    overflow-x: auto;
  }

  .date-filter {
    margin-bottom: 10px;
  }
}

.scrollable-container::-webkit-scrollbar {
  margin-top: 10px;
  width: 1rem;
  height: 0.9rem;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #e4e9f2;
  cursor: pointer;
  border-radius: 0.15625rem;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f7f9fc;
}

.chips {
  border-radius: 5px;
  border: solid 1px #eaedf3;
  padding: 6px 14px 5px;
  cursor: pointer;
  margin-right: 10px;
}

.selected-chips {
  color: white;
  background: $primary-color;
}

:focus-visible {
  outline: none;
}

.verification {
  border-radius: 10px;
  background-color: rgba(80, 165, 76, 0.17);
  padding: 10px;

  .status {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.42px;
    color: #5eaf44;
  }

  .sub-details-value {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.36px;
    text-align: left;
    color: #44546a;
  }
}

.not-verified {
  background-color: rgba(177, 59, 59, 0.17) !important;

  .status {
    color: #b13b3b !important;
  }
}

.cashout-custom {
  margin-top: auto;
  margin-bottom: auto;
  height: 55px;
}

.transaction-details-heading {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.42px;
  color: $primary-color;
}

.fixed-details-height {
  height: 60px;
}

.sub-details-heading-sm {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.36px;
  color: $primary-color;
}

.sub-details-heading {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: 0.42px;
  color: $primary-color;
}

.sub-details-val {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.36px;
  color: #acb1b7;
}

.text-small {
  text-transform: lowercase !important;
}

.img-placeholder {
  margin-right: 7px;
  cursor: pointer;
  border-radius: 10px;
  width: 64px;
  height: 100px;

  .verification-img {
    object-fit: cover;
    width: inherit;
    height: inherit;
    border-radius: 10px;
  }
}

.lg-img-placeholder {
  width: 100%;
  height: 266px;
  border-radius: 10px;

  .lg-verification-img {
    width: inherit;
    height: inherit;
    // width: 544px;
    // height: 343px;
    border-radius: 10px;
    object-fit: cover;
  }

  .cover {
    object-fit: cover;
  }

  .scale {
    object-fit: scale-down !important;
    object-position: left !important;
  }
}

.ck-toolbar_grouping {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: #f9fafb;
  border: solid 1px #eaedf3 !important;
}

.ck-editor__editable_inline {
  min-height: 250px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: solid 1px #eaedf3 !important;

  p {
    color: $primary-color;
    line-height: 2;
  }
}

.ref-code {
  margin-top: 10px;
  font-size: 14px;
  color: #acb1b7;
}

.ref-code-val {
  color: $theme-deafult;
  font-size: 15px;
  font-weight: 700;
}

.pointer-events {
  pointer-events: none;
}

.transaction-id {
  color: $theme-deafult;
  font-size: 15px;
  font-weight: 700;
}

.theme-bg {
  background: $primary-color !important;
}

.dialog-msg {
  font-size: 16px;
  padding: 20px;
  font-weight: 600;
}

.btn-danger {
  color: #ffffff;
  border-radius: 10px !important;
  float: right;
  text-transform: capitalize;
  padding-inline: 15%;
}

.btn-default {
  color: #44546a;
  background-color: #eaedf3;
  border-radius: 10px !important;
  text-transform: capitalize;
  padding-inline: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.btn-round {
  color: #44546a;
  background-color: #eaedf3;
  border-radius: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 35px;
  height: 35px;
  padding: 0;
  margin-inline: 4px;
}

.order-color-peach {
  background-color: #fcdfa6;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}

.order-color-beige {
  background-color: #dfbe99;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}

.order-color-purpl {
  background-color: #cbbaed;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}

.order-color-teal {
  background-color: #187795;
  width: 8px;
  height: 8px;
  margin-top: 7px;
  border-radius: 2px;
}

.mat-dialog-container {
  .identity_check_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .details-heading {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.72px;
      color: #44546a;
      text-align: center;
    }

    .details-sub-heading {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.42px;
      color: #44546a;
      text-align: center;
      text-decoration: underline;
    }

    .approval_button {
      background: #eaedf3 0% 0% no-repeat padding-box;
      height: 117px;
      min-width: 130px;
      border-radius: 10px;
      border: none;
      margin-left: 15px;
      margin-right: 15px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0px;
      color: #44546a;

      .fa {
        margin-bottom: 23px;
        color: #44546a;
      }

      &.selected {
        background: #e1f0e0 0% 0% no-repeat padding-box;
        border: 1px solid #eaedf3;
        color: #5eaf44;

        .fa {
          color: #5eaf44;
        }
      }

      &.selected2 {
        background: #44546a 0% 0% no-repeat padding-box;
        border: 1px solid #44546a;
        color: #ffffff;

        .fa {
          color: #ffffff;
        }
      }
    }

    .btn-cancel {
      background: #44546a22 0% 0% no-repeat padding-box !important;
      border-radius: 10px;
    }
  }
}

.visually-hidden {
  display: none;
}

.custom .mat-dialog-container {
  border-radius: 20px !important;
}

.ag-cell {
  display: flex !important;
  align-items: center !important;
}
