/* You can add global styles to this file, and also import other style files */

// html,
// body {
//   height: 100%;
// }

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cdk-overlay-container {
  #purple-date-picker {
    &.mat-dialog-container {
      padding: 24px 24px 6px;
    }
  }
}

.modal {
  z-index: 0 !important;
}
