.toast-container {
  top: 60px;
  .toast-success, .toast-error, .toast-warning, .toast-info {
    background-image: none;
  }
  .toast-warning {
    background-color: #FFAD33;
  }
  .ngx-toastr {
    padding: 15px;
    opacity: 0.85 !important;
    min-width: 400px !important;
    width: 400px !important;
    text-align: center;
    box-shadow: none;
    background-size: 18px;
    &:hover {
      box-shadow: 0 0 2px #000;
    }
    .toast-message {
      padding-left: 15px;
    }
    .toast-close-button {
      width: 18px;
      height: 18px;
      line-height: 32px;
      padding: 0px;
      color: #FFFFFF;
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
      font-size: 18px;
      &:hover {
        color: #FFFFFF;
      }
    }
  }
}
